
const isDomainRebrand = window.location.hostname.endsWith(process.env.VUE_APP_DOMAIN_REBRAND || 'hotmart.com');

const klickpagesDomainsUrls = {
  send_client_domain: process.env.VUE_APP_SEND_CLIENT_DOMAIN,
  send_api_endpoint: process.env.VUE_APP_API_ENDPOINT,
  handler_endpoint: process.env.VUE_APP_HANDLER_ENDPOINT,
  metrics_endpoint: process.env.METRICS_ENDPOINT,
  form_recaptcha_static_url: process.env.VUE_APP_FORM_RECAPTCHA_STATIC_URL,
  klickart_url: process.env.VUE_APP_KLICKART_URL,
  klickart_host: process.env.VUE_APP_KLICKART_HOST,
  klickpages_domain: process.env.VUE_APP_KLICKPAGES_DOMAIN,
  klickpages_page_manager_domain: process.env.VUE_APP_PAGE_MANAGER_DOMAIN,
  klickart_auth_uri: process.env.VUE_APP_KLICKART_AUTH_URI,
  editor_host: process.env.VUE_APP_EDITOR_HOST,
  klicksend_logo_url: process.env.VUE_APP_KLICKSEND_LOGO_URL,
  hotmart_domain: process.env.VUE_APP_HOTMART_DOMAIN,
};

const hotmartDomainsUrls = {
  send_client_domain: process.env.VUE_APP_SEND_CLIENT_DOMAIN_REBRAND,
  send_api_endpoint: process.env.VUE_APP_API_ENDPOINT_REBRAND,
  handler_endpoint: process.env.VUE_APP_HANDLER_ENDPOINT_REBRAND,
  metrics_endpoint: process.env.METRICS_ENDPOINT_REBRAND,
  form_recaptcha_static_url: process.env.VUE_APP_FORM_RECAPTCHA_STATIC_URL_REBRAND,
  klickart_url: process.env.VUE_APP_KLICKART_URL_REBRAND,
  klickart_host: process.env.VUE_APP_KLICKART_HOST_REBRAND,
  klickpages_domain: process.env.VUE_APP_KLICKPAGES_DOMAIN_REBRAND,
  klickpages_page_manager_domain: process.env.VUE_APP_PAGE_MANAGER_DOMAIN_REBRAND,
  klickart_auth_uri: process.env.VUE_APP_KLICKART_AUTH_URI_REBRAND,
  editor_host: process.env.VUE_APP_EDITOR_HOST_REBRAND,
  klicksend_logo_url: process.env.VUE_APP_KLICKSEND_LOGO_URL_REBRAND,
  hotmart_domain: process.env.VUE_APP_HOTMART_DOMAIN_REBRAND,
};
const envUrls = isDomainRebrand ? hotmartDomainsUrls : klickpagesDomainsUrls;

export { envUrls, isDomainRebrand, hotmartDomainsUrls };

export default envUrls;
